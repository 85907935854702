import axios from "axios";
import router from "next/router";
import { getAuthToken } from "../../hooks/getAuthUserId";
import { stripeCreateCheckoutSession } from "../UpdateCardPayment/utils";
import { api } from "../../helpers/topline-api";

export const createStripeCheckoutSession = async (
  subscriptionPlanId: string,
  landingPageId: string
) => {
  try {
    const coupon_id = (router?.query?.coupon_id as string) ?? "";

    const success_url = `${window.location.origin}/onboarding?success=true&session_id={CHECKOUT_SESSION_ID}`;
    const cancel_url = window.location.href;

    const session = await stripeCreateCheckoutSession(
      subscriptionPlanId,
      coupon_id,
      success_url,
      cancel_url,
      landingPageId
    );

    return session;
  } catch (e) {
    console.error(e);
  }
};

export const updateUserInfo = async (
  auth0: string,
  input: {
    landing_page_id: string;
  }
) => {
  try {
    await axios.put(
      "/api/prosite/user",
      {
        auth0: `${auth0}`,
        input: {
          landing_page_id: input.landing_page_id,
        },
      },
      {
        headers: {
          authorization: `Bearer ${await getAuthToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export async function updateCustomEmails(emailAddresses: any, slug: string) {
  try {
    const response = await api.post(`/api/update-custom-emails`, {
      email_addresses: emailAddresses,
      slug: slug,
    });
    return response;
  } catch (e) {
    console.log("error", e);
  }
}

export async function setDownloadAppPopupFalseDb() {
  try {
    const response = await api.get(`/api/set-download-app-popup-false`);
    const responseData = response.data.message;
    return responseData;
  } catch (e) {
    console.log("error set-download-app-popup-false", e);
    return;
  }
}

export async function addTeammate(
  slug: string,
  teammateName: string,
  email: string,
  phone: string
) {
  const response = await api.post(`/api/add-teammate`, {
    slug,
    name: teammateName,
    email,
    phone,
  });
  const responseData = response.data;
  return responseData;
}

export async function sendTeammateInvitation(
  isEmail,
  teammateName,
  landingPageId,
  teammatePk,
  primaryOwnerName
) {
  try {
    const response = await api.post(`/api/send-teammate-invite`, {
      isEmail,
      teammateName,
      landingPageId,
      teammatePk,
      primaryOwnerName,
    });
    const responseData = response.data;
    return responseData;
  } catch (e) {
    console.log("error sendTeammateInvitation", e);
    return { status: "error" };
  }
}
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export async function checkSentMessageSuccessfully(messageId: string) {
  try {
    await delay(5000);
    const response = await api.post(
      `/api/check-if-courier-message-sent-successfully`,
      {
        message_id: messageId,
      }
    );
    const responseData = response.data;
    console.log(
      "about to return in checkSentMessageSuccessfully",
      responseData
    );
    return responseData;
  } catch (e) {
    console.log("error checkSentMessageSuccessfully", e);
    return { status: "error" };
  }
}
